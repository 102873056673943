const dataAddreess = {
  MX: {
    title: '¿A dónde enviamos tus productos?',
    titleNonDelirable: 'Dirección de facturación',
  },
  CO: {
    title: 'Dirección de facturación',
    titleNonDelirable: 'Dirección de facturación',
  },
  CL: {
    title: 'Dirección de envío',
    titleNonDelirable: 'Dirección de facturación',
  },
  PE: {
    title: 'Dirección de facturación',
    titleNonDelirable: 'Dirección de facturación',
  },
  Default: {
    title: '¿A dónde enviamos tus productos?',
    titleNonDelirable: 'Dirección de facturación',
  },
};

export default dataAddreess;
