import Constants from '../../../utils/Constants';

const { assetsURL } = Constants;

const atrato1x = `${assetsURL}/images/atrato@1x.webp`;
const atrato2x = `${assetsURL}/images/atrato@2x.webp`;
const atrato3x = `${assetsURL}/images/atrato@3x.webp`;
const mercado1x = `${assetsURL}/images/mercado@1x.webp`;
const mercado2x = `${assetsURL}/images/mercado@2x.webp`;
const mercado3x = `${assetsURL}/images/mercado@3x.webp`;
const stripe1x = `${assetsURL}/images/stripe@1x.webp`;
const stripe2x = `${assetsURL}/images/stripe@2x.webp`;
const stripe3x = `${assetsURL}/images/stripe@3x.webp`;

export {
  atrato1x,
  atrato2x,
  atrato3x,
  mercado1x,
  mercado2x,
  mercado3x,
  stripe1x,
  stripe2x,
  stripe3x,
};
