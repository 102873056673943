const {
  REACT_APP_ASSETS_URL,
  REACT_APP_CITA_ID,
  REACT_APP_SHIPPING_METHOD,
  REACT_APP_PAGO_COMPLEMENTARIO,
  REACT_APP_APARTADO,
} = process.env;

const Constants = {
  assetsURL: REACT_APP_ASSETS_URL,
  CitaId: REACT_APP_CITA_ID,
  ShippingId: REACT_APP_SHIPPING_METHOD,
  PagoComplementario: REACT_APP_PAGO_COMPLEMENTARIO,
  Apartado: REACT_APP_APARTADO,
};

export default Constants;
