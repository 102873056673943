/* eslint-disable */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, Input } from '@mymoons/ui-library';
import { Box } from '../../../../components';
import styles from '../../Summary.module.css';
import {
  axiosPutAddCoupon,
  axiosPutRemoveCoupon,
} from '../../../../methods/globalData/axiosPut';
import { close, backCheckout } from '../../../../assets/images/Global';
import ProductContext from '../../../../context/Product/Context';
import totalPriceCarts from '../../../../hooks/totalCarts';
import PaymentContext from '../../../../context/Payment/Context';
import stylesTooltip from '../../../Payment/Payment.module.css';
import GlobalContext from '../../../../context/Global/Context';
import AlertModal from '../../../../components/AlertModal';
import {
  processDiscountCodes,
  computeDiscountCodeToRemove,
} from '../../../../utils/discounts';
import { numberWithCommas } from '../../../../utils/cardForm';

const Coupon = () => {
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const productContext = useContext(ProductContext);
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const [inputCoupon, setInputCoupon] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [couponPosition, setCouponPosition] = useState(0);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [lineItemsNew, setLineItems] = useState([]);
  const [lineItemsOriginal, setLineItemsOriginal] = useState([]);
  const [disableApply, setDisableApply] = useState(true);
  const {
    carts: cartsNew,
    setCarts,
    couponsCode,
    setCoupons,
    setCouponsCode,
    setProductsWithDiscount,
  } = productContext;
  const { redirectUrl } = globalContext;
  const { address } = paymentContext;
  const totalPrice = totalPriceCarts(cartsNew, false);
  let cost = 0;
  const disabledCoupon = address.ChangePaymentType;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;

  const dataPreventModal = {
    title: 'Modificación en tu carrito',
    description:
      'El precio de tu pedido se redujo y las condiciones de tu pago a meses cambiaron. Vuelve a seleccionar una opción de pago a meses.',
    button: 'Confirmar',
    discard: 'Permanecer aquí',
    imgAlert: backCheckout,
    urlRedirect: redirectUrl,
    onlyPrimaryButton: true,
    isButtonPrimaryAction: true,
  };

  useEffect(() => {
    if (showAlertModal) {
      $html.style.height = '100vh';
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      return;
    }

    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, [showAlertModal]);

  const handleSubmit = useCallback(
    async (e) => {
      setLoader(true);
      e.preventDefault();
      e.stopPropagation();
      setCouponError(null);
      const findCoupon = couponsCode.find((couponToFind) => {
        return coupon === couponToFind.code;
      });
      if (!findCoupon) {
        axiosPutAddCoupon(carts[0], coupon)
          .then((res) => {
            setShowAlertModal(true);
            setLineItems([...lineItemsNew, res.data.lineItems]);
            setCarts([res.data]);
            setCoupons(res.data.discountCodes);
            setProductsWithDiscount(res.data.lineItems);
            processDiscountCodes(
              res.data.lineItems,
              res.data.customLineItems
            ).then((responseCouponsProcess) => {
              const newArrayCouponsCode = [];
              responseCouponsProcess.forEach((discount) => {
                newArrayCouponsCode.push({
                  id: discount.id,
                  code: discount.code,
                  discount: discount.discount,
                });
              });
              setCouponsCode(newArrayCouponsCode);
            });
            setCouponPosition(couponPosition + 1);
            setCoupon('');
            setLoader(false);
            setInputCoupon(false);
          })
          .catch(() => {
            setCoupon('');
            setLoader(false);
            setCouponError('El cupón es inválido o ha expirado');
            setTimeout(() => {
              setCouponError(null);
            }, 3000);
          });
      } else {
        setCoupon('');
        setLoader(false);
        setCouponError('El cupón ya se ha aplicado');
        setTimeout(() => {
          setCouponError(null);
        }, 3000);
      }
    },
    [coupon]
  );

  useEffect(() => {
    if (couponsCode.length > 0) {
      setHasCoupon(true);
      localStorage.setItem('discount', JSON.stringify(couponsCode));
    } else {
      setHasCoupon(false);
      localStorage.setItem('discount', JSON.stringify('false'));
    }
  }, [couponsCode]);

  useEffect(() => {
    const { lineItems } = cartsNew[0];
    lineItems.forEach((item) => {
      cost = +cost + (item.price.value.centAmount / 100) * item.quantity;
      setOriginalPrice(originalPrice + cost);
    });
  }, []);

  useEffect(() => {
    if (inputCoupon.length > 1) {
      const inputFocus = document.querySelector('#couponInput');
      inputFocus.focus();
    }
  }, [inputCoupon]);

  useEffect(() => {
    if (coupon.length === 0) {
      setDisableApply(true);
    } else {
      setDisableApply(false);
    }
  }, [coupon]);

  const handleRemoveCoupon = async (cartDiscount) => {
    const matchesCart = cartsNew[0]?.discountCodes.filter(
      (discCode) => discCode.state === 'MatchesCart'
    );
    if (matchesCart && matchesCart.length > 0) {
      const codeToRemove = await computeDiscountCodeToRemove(
        matchesCart ?? [],
        cartDiscount.id
      );
      if (codeToRemove) {
        try {
          const removeCouponPayload = {
            id: codeToRemove,
            typeId: 'discount-code',
          };

          axiosPutRemoveCoupon(carts[0], removeCouponPayload)
            .then((res) => {
              setCarts([res.data]);
              setShowAlertModal(true);
              processDiscountCodes(
                res.data.lineItems,
                res.data.customLineItems
              ).then((responseCouponsProcess) => {
                setCouponsCode([]);
                const newArrayCouponsCode = [];
                responseCouponsProcess.forEach((discount) => {
                  newArrayCouponsCode.push({
                    id: discount.id,
                    code: discount.code,
                    discount: discount.discount,
                  });
                });
                setCouponsCode(newArrayCouponsCode);
              });
              setLineItemsOriginal([...lineItemsOriginal, res.data.lineItems]);
              setProductsWithDiscount([]);
              setCoupons(res.data.discountCodes);
              setCoupon('');
              setLoader(false);
              setInputCoupon(true);
            })
            .catch(() => {
              setLoader(false);
            });
        } catch {
          setLoader(false);
        }
      }
    }
  };

  return (
    <>
      {showAlertModal && (
        <AlertModal data={dataPreventModal} modal={setShowAlertModal} />
      )}
      <form className={styles.formCoupon} onSubmit={handleSubmit}>
        <Box ai="flex-start">
          {!hasCoupon && !inputCoupon ? (
            <ReactTooltip
              place="top"
              id="tooltip-error-coupon"
              disable={!disabledCoupon}
              effect="solid"
              offset="{'left': 80}"
              className={stylesTooltip.customTooltipAddress}
              backgroundColor="#212121"
            >
              No es posible agregar un cupón una vez realizada una orden
            </ReactTooltip>
          ) : (
            <div className={styles.couponsContainer}>
              {couponsCode.map((code) => {
                return (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    key={code.id}
                  >
                    <div className={styles.promoContainer}>
                      <span>{code.code}</span>
                      {!disabledCoupon && (
                        <button
                          id="Check_Summary_EraseCoupon"
                          onClick={() => {
                            handleRemoveCoupon(code);
                          }}
                          type="button"
                        >
                          <img
                            width={9.33}
                            height={9.33}
                            src={close}
                            alt="Times"
                          />
                        </button>
                      )}
                    </div>
                    <p>{`-$${numberWithCommas(code.discount)}`}</p>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.containerCoupon}>
            <Input
              id="couponInput"
              className={styles.inputCoupon}
              error={couponError}
              iconVariant="regular"
              label=""
              placeholder="Ingresar cupón"
              value={coupon}
              variant="outline"
              fullWidth
              pattern="[a-zA-Z0-9\-]+"
              onChange={(e) => {
                setCoupon(e.target.value);
              }}
            />
            <Button
              id="Check_Summary_AddCoupon_Action"
              className={styles.couponButton}
              color="red"
              label="Aplicar"
              isButton
              rel="noopener noreferrer"
              size="small"
              variant="text"
              type="submit"
              loading={loader}
              disabled={disableApply}
            />
          </div>
        </Box>
      </form>
    </>
  );
};

export default Coupon;
