import axios from 'axios';

const { REACT_APP_CHECKOUT_API_URL } = process.env;

const getPSEBanks = async () => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };
  const response = axios.get(
    `${REACT_APP_CHECKOUT_API_URL}/pse-banks`,
    options,
  );

  return response;
};

const getPSEBanks2 = async () => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };
  const response = axios.get(
    `${REACT_APP_CHECKOUT_API_URL}/pse-banks`,
    options,
  );

  return response;
};

export { getPSEBanks, getPSEBanks2 };
