import convertPrice from './convertPrice';

/**
 * Return total price of all carts
 *
 * @param {array} carts
 * @param {boolean} isToShow when is true the function return with commas the price
 * @returns {string} final price carts
 */
function totalPriceCarts(carts, isToShow) {
  let totalPrice = '';

  carts.forEach((cartsArr) => {
    const { centAmount } = cartsArr.totalPrice;
    totalPrice = +centAmount / 100 + +totalPrice;
  });

  return isToShow ? convertPrice(Number(totalPrice).toFixed(2)) : Number(totalPrice);
}

export default totalPriceCarts;
