export const data = {
  titleSelect: 'Tarjetas guardadas',
  titleCreate: 'Nueva tarjeta',
  titleEdit: 'Editar Tarjeta',
  name: 'Nombre del titular de la tarjeta',
  nameHolder: 'Nombre y apellidos',
  info: 'Número de tarjeta',
  cardHolder: '4242 4242 4242 4242',
  monthHolder: '01/29',
  cvcHolder: '1234',
  save: 'Deseo guardar esta tarjeta.',
  list: 'LISTA DE TARJETAS GUARDADAS',
  buttonText: 'Agregar',
  tipoDocumento: 'Tipo de documento',
  numDocumento: 'Número del documento',
  holderDocumento: 'Ingresa el número del documento',
  document: {
    CO: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'Cédula de ciudadanía',
        selected: false,
        value: 'CC',
        regex: 'num',
      },
      {
        id: 2,
        label: 'Cédula de extranjería',
        selected: false,
        value: 'CE',
        regex: 'alfa',
      },
      {
        id: 3,
        label: 'Número de Identificación Tributario',
        selected: false,
        value: 'NIT',
        regex: 'num',
      },
      {
        id: 4,
        label: 'Tarjeta de Identidad',
        selected: false,
        value: 'Otro',
        regex: 'num',
      },
      {
        id: 5,
        label: 'Pasaporte',
        selected: false,
        value: 'Otro',
        regex: 'alfa',
      },
      {
        id: 6,
        label: 'Identificador único de cliente',
        selected: false,
        value: 'Otro',
        regex: 'alfa',
      },
      {
        id: 7,
        label: 'Número móvil',
        selected: false,
        value: 'Otro',
        regex: 'num',
      },
      {
        id: 8,
        label: 'Registro civil',
        selected: false,
        value: 'Otro',
        regex: 'num',
      },
      {
        id: 9,
        label: 'Documento de identificación extranjero',
        selected: false,
        value: 'Otro',
        regex: 'alfa',
      },
    ],
    PE: [
      {
        id: 0,
        label: 'Selecciona una opción',
        selected: false,
        value: 0,
        regex: null,
      },
      {
        id: 1,
        label: 'DNI',
        selected: false,
        value: 'DNI',
        regex: 'num',
      },
    ],
  },
};

export const paymentInstallments = [
  {
    months: 3,
    min: 299,
    max: 599,
    taxe: 0.0297,
  },
  {
    months: 6,
    min: 600,
    max: 899,
    taxe: 0.0549,
  },
  {
    months: 9,
    min: 900,
    max: 1199,
    taxe: 0.0925,
  },
  {
    months: 12,
    min: 1200,
    max: 1799,
    taxe: 0.1253,
  },
  {
    months: 18,
    min: 1800,
    max: 2399,
    taxe: 0.168,
  },
  {
    months: 24,
    min: 2400,
    max: 100000,
    taxe: 0.2421,
  },
];

export const currencyInstallments = {
  MX: 'MXN',
  CO: 'COP',
};
