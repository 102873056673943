import React, { useContext, useEffect, useState } from 'react';
import {
  products1x,
  productMoons1x,
  productMoons2x,
  productMoons3x,
} from '../../assets/images/Global';
import { Box, Text, CardProduct } from '../../components';
import useMediaQuery from '../../hooks/useMediaQuery';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
import GlobalContext from '../../context/Global/Context';
import ResumeModalMobile from './components/ResumeModalMobile';
import styles from './Summary.module.css';
import data from './data.json';
import useProductDataByCountry from '../../hooks/useProductDataByCountry';
import totalPriceCarts from '../../hooks/totalCarts';
import Coupon from './components/Coupon';
import convertPrice from '../../hooks/convertPrice';
import { getTotalPrice } from '../../utils/cardForm';
import { availableCountries } from '../../utils/locale';

const Summary = () => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { title } = data;
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const {
    productsMobile,
    setTotalPrice,
    paymentInstallments,
  } = paymentContext;
  const { carts, products } = productContext;
  const {
    isCitaFlow,
    appointmentCenter,
    appointmentDate,
    isOnlySettlement,
    isOnlyAdvance,
    isOnlyComplementaryPayment,
    country,
  } = globalContext;
  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  let scrollPosition = 0;
  const [productsToShow, setProductsToShow] = useState([]);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [showDelivery, setShowDelivery] = useState(true);
  const totalCarts = totalPriceCarts(carts, true);
  const { lineItems } = carts[0];
  let cost = 0;
  useEffect(() => {
    setTotalPrice(totalCarts);
  }, [totalCarts]);

  useEffect(() => {
    lineItems.forEach((item) => {
      if (item.price.discounted) {
        cost = +cost + (item.price.discounted.value.centAmount / 100) * item.quantity;
      } else {
        cost = +cost + (item.price.value.centAmount / 100) * item.quantity;
      }
      const priceConv = convertPrice(Number(originalPrice + cost).toFixed(2));
      setOriginalPrice(priceConv);
    });
  }, [lineItems]);

  // TODO The following lines can be handler in the product context
  if (productsToShow.length < products.length) {
    const converted = useProductDataByCountry(products, true, true);
    setProductsToShow(converted);
  }

  useEffect(() => {
    if (productsMobile) {
      $html.style.height = '100vh';
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      return;
    }

    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, [productsMobile]);

  useEffect(() => {
    if (isCitaFlow || isOnlySettlement || isOnlyAdvance || isOnlyComplementaryPayment) {
      setShowDelivery(false);
    }
  }, [isCitaFlow, isOnlySettlement, isOnlyAdvance, isOnlyComplementaryPayment]);

  return (
    <div className={styles.containerMain}>
      <Box fd="column" ai="flex-start" p={isMobile ? '0' : '0'}>
        <link rel="preload" as="image" href={products1x} src={products1x} />
        {!isMobile && (
          <>
            <h2 className={styles.title}>{title}</h2>
            <Box fd="row" jc="space-between" p="8px 0 40px 0">
              <Text id="Check_Summary_TotalSummary" f="Poppins Medium" s={32} lh={48}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                { getTotalPrice(totalCarts, paymentInstallments, country) }
              </Text>
            </Box>
            <Box>
              {productsToShow.map((product) => {
                return (
                  <CardProduct
                    key={product.name}
                    img1x={
                      product.images[0] ? product.images[0].url : productMoons1x
                    }
                    img2x={
                      product.images[1] ? product.images[1].url : productMoons2x
                    }
                    img3x={
                      product.images[2] ? product.images[2].url : productMoons3x
                    }
                    title={product.name}
                    principalPrice={getTotalPrice(
                      product.principalPrice,
                      0,
                      country,
                    )}
                    quantity={product.quantity}
                    secondaryPrice={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}${getTotalPrice(product.totalPrice, 0, country)} por unidad`}
                    isPromo={product.hasDiscount}
                    isCitaFlow={product.productId === process.env.REACT_APP_CITA_ID && true}
                    appointmentDate={
                      product.productId === process.env.REACT_APP_CITA_ID
                      && appointmentDate
                    }
                    appointmentCenter={
                      product.productId === process.env.REACT_APP_CITA_ID
                      && appointmentCenter
                    }
                    countryCurrency={`${country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}`}
                  />
                );
              })}
            </Box>
            <div className={styles.line} />
            <Box>
              <Box fd="row" jc="space-between" p="0 0 16px 0">
                <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                  Total en tu carrito
                </Text>
                <Text id="Check_Summary_TotalOriginalPrice" c="#757575" f="Inter Regular" s={14} lh={20}>
                  {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                  { getTotalPrice(totalCarts, paymentInstallments, country) }
                </Text>
              </Box>
              {showDelivery && (
                <Box fd="row" jc="space-between">
                  <Box fd="column" ai="flex-start" p="0 0 16px 0">
                    <Text
                      p="0 0 4px 0"
                      c="#757575"
                      f="Inter Regular"
                      s={14}
                      lh={20}
                    >
                      Envío
                    </Text>
                    <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                      Tiempo aproximado: entre 2 y 5 días hábiles
                    </Text>
                  </Box>
                  <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                    Gratis
                  </Text>
                </Box>
              )}
            </Box>
            <Coupon />
            <div className={styles.line} />
            <Box fd="row" jc="space-between">
              <Text c="#757575" f="Inter Regular" s={14} lh={20}>
                Total
              </Text>
              <Text id="Check_Summary_TotalWithDiscount" c="#616161" f="Inter Bold" s={14} lh={20}>
                {country.toLocaleLowerCase() !== 'pe' ? '$' : 'S/ '}
                {getTotalPrice(totalCarts, paymentInstallments, country)}
                {' '}
                {country.toLocaleLowerCase() !== 'pe' ? availableCountries[country.toLocaleLowerCase()].currency : ''}
              </Text>
            </Box>
          </>
        )}
        {isMobile && (
          <ResumeModalMobile />
        )}
      </Box>
    </div>
  );
};

export default Summary;
