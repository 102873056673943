import {
  USER_INFO,
  DATA_OXXO,
  DATA_EFECTY,
  DATA_SPEI,
  DATA_PSE,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        user: action.payload,
      };

    case DATA_OXXO:
      return {
        ...state,
        dataOxxo: action.payload,
      };

    case DATA_EFECTY:
      return {
        ...state,
        dataEfecty: action.payload,
      };

    case DATA_SPEI:
      return {
        ...state,
        dataSpei: action.payload,
      };

    case DATA_PSE:
      return {
        ...state,
        dataPSE: action.payload,
      };

    default:
      return state;
  }
};
