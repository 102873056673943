const dataAddreessSelector = {
  MX: {
    defaultText: 'Ingresa tu dirección de envío',
  },
  CL: {
    defaultText: 'Ingresa tu dirección para continuar',
  },
  CO: {
    defaultText: 'Ingresa tu dirección para continuar',
  },
  PE: {
    defaultText: 'Ingresa tu dirección para continuar',
  },
  Default: {
    defaultText: 'Ingresa tu dirección para continuar',
  },
};
export default dataAddreessSelector;
