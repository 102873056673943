import React from 'react';
import PropTypes from 'prop-types';

const Text = ({
  f, // font,
  c, // color,
  s, // size,
  w, // weight,
  a, // align,
  lh, // lineHeight,
  p, // padding
  ls, // letterSpacing
  className,
  children,
  id,
}) => {
  const styles = {
    fontFamily: `${f}, sans-serif`,
    fontWeight: w,
    fontSize: s,
    textAlign: a,
    lineHeight: `${lh}px`,
    color: c,
    padding: p,
    letterSpacing: ls,
  };

  return (
    <span id={id} className={className} style={styles}>
      {children}
    </span>
  );
};

Text.propTypes = {
  f: PropTypes.string,
  c: PropTypes.string,
  s: PropTypes.number,
  w: PropTypes.number,
  a: PropTypes.string,
  lh: PropTypes.number,
  p: PropTypes.string,
  ls: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
  id: PropTypes.node,
};

Text.defaultProps = {
  f: 'Inter Regular',
  c: '#212121',
  s: 18,
  w: 500,
  a: 'left',
  lh: 24,
  p: '0px',
  ls: '',
  className: null,
  id: null,
};

export default Text;
