const errors = [
  {
    code: 'cc_rejected_other_reason',
    text: 'Tarjeta rechazada. Elige otra tarjeta u otro medio de pago.',
  },
  {
    code: 'cc_rejected_bad_filled_date',
    text: 'Revisa la fecha de vencimiento de tu tarjeta.',
  },
  {
    code: 'cc_rejected_call_for_authorize',
    text: 'Tarjeta rechazada. Elige otra tarjeta u otro medio de pago.',
  },
  {
    code: 'cc_rejected_insufficient_amount',
    text: 'Fondos insuficientes. Elige otra tarjeta u otro medio de pago.',
  },
  {
    code: 'cc_rejected_bad_filled_security_code',
    text: 'Revisa el código de seguridad de la tarjeta.',
  },
  {
    code: 'cc_rejected_high_risk',
    text: 'Tarjeta rechazada. Elige otra tarjeta u otro medio de pago.',
  },
  {
    code: 'pending_review_manual',
    text: 'Estamos procesando tu pago. En menos de 2 días hábiles te avisaremos por e-mail si se acreditó.',
  },
  {
    code: 'cc_rejected_bad_filled_card_number',
    text: 'Revisa el número de tarjeta.',
  },
  {
    code: 'cc_rejected_blacklist',
    text: 'Tarjeta rechazada. Elige otra tarjeta u otro medio de pago.',
  },
  {
    code: 'cc_rejected_max_attempts',
    text: 'Tarjeta rechazada. Elige otra tarjeta u otro medio de pago.',
  },
];
function errorHandler(message) {
  const parseError = errors.filter((error) => {
    return message.includes(error.code);
  });
  if (parseError.length > 0) {
    return parseError[0].text;
  }
  return 'El método de pago es erróneo';
}

export default errorHandler;
