/* eslint-disable */
import React, { Suspense, lazy, useEffect } from 'react';
import ErrorPage from '@mymoons/error-page';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import sentryInit from './utils/sentryInit';

const Payment = lazy(() => import('./pages/Payment'));

const App = () => {
  useEffect(() => {
    sentryInit();
  }, []);

  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<Payment status="normal" />} />
          <Route path="/payment/oxxo" element={<Payment status="oxxo" />} />
          <Route path="/payment/spei" element={<Payment status="spei" />} />
          <Route path="/payment/atrato" element={<Payment status="atrato" />} />
          <Route path="/payment/paypal" element={<Payment status="paypal" />} />
          <Route path="/payment/pse" element={<Payment status="pse" />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
