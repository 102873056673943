import axios from 'axios';
import PropTypes from 'prop-types';

const { REACT_APP_ECOMMERCE_BASE_URL } = process.env;

const axiosPutRecalculateCarts = (cartIds) => {
  const body = { cartIds };

  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };

  return axios.put(`${REACT_APP_ECOMMERCE_BASE_URL}/carts/recalculate`, body, options);
};

axiosPutRecalculateCarts.propTypes = {
  cartIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export { axiosPutRecalculateCarts };
