/* eslint-disable quotes */
/* eslint-disable no-debugger */
/* eslint-disable eqeqeq */
import * as Yup from 'yup';

export const data = {
  MX: {
    titleSelect: 'Direcciones guardadas',
    titleCreate: 'Dirección de envío',
    titleNonDelirable: 'Dirección de facturación',
    titleEdit: 'Editar dirección',
    street: 'Calle*',
    streetHolder: 'Ej: León de los aldamas',
    numExt: 'Número ext.*',
    numExtHolder: 'Ej: 110',
    numInt: 'Número int.',
    numIntHolder: 'Ej: 1a',
    cp: 'Código postal*',
    cpHolder: 'Ej: 06760',
    suburb: 'Colonia*',
    suburbHolder: 'Ej: Roma sur',
    state: 'Estado*',
    stateHolder: 'Ej: CDMX',
    buttonText: 'Guardar',
  },
  CO: {
    titleSelect: 'Direcciones guardadas',
    titleCreate: 'Dirección de facturación',
    titleNonDelirable: 'Dirección de facturación',
    titleEdit: 'Editar dirección',
    street: 'Calle*',
    streetHolder: 'Ej: Calle 100',
    numExt: 'Número*',
    numExtHolder: 'Ej: #13-21',
    numInt: 'Apto (Opcional)',
    numIntHolder: 'Ej: 104',
    cp: null,
    cpHolder: null,
    state: 'Ciudad/Municipio*',
    stateHolder: 'Ej: Bogotá',
    suburb: 'Barrio*',
    suburbHolder: 'Ej: Multicentro',
    department: 'Departamento*',
    departmentHolder: 'Ej: Cundinamarca',
    buttonText: 'Guardar',
  },
  CL: {
    titleSelect: 'Direcciones guardadas',
    titleCreate: 'Dirección de envío',
    titleNonDelirable: 'Dirección de facturación',
    titleEdit: 'Editar dirección',
    street: 'Calle*',
    streetHolder: 'Ej: Samuel Valencia',
    numExt: 'Número ext.*',
    numExtHolder: 'Ej: 840',
    numInt: 'Número int. (Opcional)',
    numIntHolder: 'Ej: 104',
    cp: null,
    cpHolder: null,
    state: 'Comuna*',
    stateHolder: 'Ej: Quilpué',
    suburb: 'Región*',
    suburbHolder: 'Ej: Valparaiso',
    department: null,
    departmentHolder: null,
    buttonText: 'Guardar',
  },
  PE: {
    titleSelect: 'Direcciones guardadas',
    titleCreate: 'Dirección de facturación',
    titleNonDelirable: 'Dirección de facturación',
    titleEdit: 'Editar dirección',
    street: 'Calle*',
    streetHolder: 'Ej: Convención',
    numExt: 'Número ext*',
    numExtHolder: 'Ej: 1476',
    numInt: 'Número int. (Opcional)',
    numIntHolder: 'Ej: 104',
    cp: null,
    cpHolder: null,
    state: 'Distrito*',
    stateHolder: 'Ej: Cosme',
    suburb: 'Provincia*',
    suburbHolder: 'Ej: Churcampa',
    department: 'Departamento*',
    departmentHolder: 'Ej: Huancavelica',
    buttonText: 'Guardar',
  },
  Default: {
    titleSelect: 'Direcciones guardadas',
    titleCreate: 'Dirección de envío',
    titleNonDelirable: 'Dirección de facturación',
    titleEdit: 'Editar dirección',
    street: 'Calle*',
    streetHolder: 'Ej: León de los aldamas',
    numExt: 'Número ext.*',
    numExtHolder: 'Ej: 110',
    numInt: 'Número int.',
    numIntHolder: 'Ej: 1a',
    cp: 'Código postal*',
    cpHolder: 'Ej: 06760',
    suburb: 'Colonia*',
    suburbHolder: 'Ej: Roma sur',
    state: 'Estado*',
    stateHolder: 'Ej: CDMX',
    buttonText: 'Guardar',
  },
};

export const YupConfigurationByCountry = {
  MX: {
    Street: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Address_Number: Yup.number()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido'),

    References: Yup.string(),
    Zip_Code: Yup.string()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido')
      .matches(/^[0-9]+$/, 'Debes de ingresar solo números')
      .min(5, 'El código postal está conformado por 5 dígitos'),
  },
  CO: {
    Street: Yup.string()
      .required('El campo es requerido')
      .matches(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ#-\s]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Address_Number: Yup.string()
      .matches(/^[A-Za-z0-9ñÑ#-\s]+$/, 'Ingresa la dirección sin carácteres especiales')
      .required('El campo es requerido'),
    References: Yup.string()
      .matches(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ#-\s]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Neighborhood: Yup.string()
      .required('El campo es requerido')
      .matches(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ-\s]+$/, 'Ingresa la dirección sin carácteres especiales'),
    State: Yup.string()
      .required('El campo es requerido')
      .matches(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ-\s]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Department: Yup.string()
      .required('El campo es requerido')
      .matches(/^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ-\s]+$/, 'Ingresa la dirección sin carácteres especiales'),
  },
  CL: {
    Street: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Address_Number: Yup.number()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido'),
    References: Yup.string(),
    Neighborhood: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    State: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
  },
  PE: {
    Street: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Address_Number: Yup.number()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido'),
    References: Yup.string(),
    Neighborhood: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    State: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Department: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
  },
  Default: {
    Street: Yup.string()
      .required('El campo es requerido')
      .matches(/^[\w ]+$/, 'Ingresa la dirección sin carácteres especiales'),
    Address_Number: Yup.number()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido'),

    References: Yup.string(),
    Zip_Code: Yup.string()
      .typeError('Debes de ingresar solo números')
      .required('El campo es requerido')
      .matches(/^[0-9]+$/, 'Debes de ingresar solo números')
      .min(5, 'El código postal está conformado por 5 dígitos'),
  },
};

export const newAddressValuesbyCountry = (dataNewAdress) => {
  const {
    country,
    firstNameValue,
    lastNameValue,
    neighborhoodSel,
    stateCode,
    emailValue,
    personID,
    values,
  } = dataNewAdress;
  const newAdress = {
    MX: {
      country,
      firstName: firstNameValue,
      lastName: lastNameValue,
      streetNumber: values.Address_Number,
      streetName: values.Street,
      postalCode: values.Zip_Code,
      additionalStreetInfo: values.References,
      city: neighborhoodSel.value,
      state: stateCode,
      email: emailValue,
      externalId: personID,
    },
    CO: {
      country,
      firstName: firstNameValue,
      lastName: lastNameValue,
      streetNumber: values.Address_Number,
      streetName: values.Street,
      apartment: values.References,
      city: values.State,
      email: emailValue,
      externalId: personID,
      department: values.Department,
      additionalStreetInfo: values.Neighborhood,
    },
    CL: {
      country,
      firstName: firstNameValue,
      lastName: lastNameValue,
      streetNumber: values.Address_Number,
      streetName: values.Street,
      apartment: values.References,
      municipality: values.State,
      email: emailValue,
      externalId: personID,
      neighborhood: values.Neighborhood,
    },
    PE: {
      country,
      firstName: firstNameValue,
      lastName: lastNameValue,
      streetNumber: values.Address_Number,
      streetName: values.Street,
      apartment: values.References,
      municipality: values.State,
      email: emailValue,
      externalId: personID,
      department: values.Department,
      neighborhood: values.Neighborhood,

    },
    Default: {
      country,
      firstName: firstNameValue,
      lastName: lastNameValue,
      streetNumber: values.Address_Number,
      streetName: values.Street,
      postalCode: values.Zip_Code,
      additionalStreetInfo: values.References,
      city: neighborhoodSel.value,
      state: stateCode,
      email: emailValue,
      externalId: personID,
    },
  };
  return newAdress[country];
};

export const valuesNewByCountry = (dataValuesNewProperties) => {
  const {
    country,
    neighborhoodSel,
    stateSelection,
    values,
  } = dataValuesNewProperties;
  const newValues = {
    MX: {
      Street: values.Street,
      Address_Number: values.Address_Number,
      References: values.References,
      Zip_Code: values.Zip_Code,
      Neighborhood: neighborhoodSel.value,
      State: [
        stateSelection?.label,
        stateSelection?.value,
        stateSelection?.id,
        stateSelection?.selected,
      ],
    },
    CO: {
      Street: values.Street,
      Address_Number: values.Address_Number,
      References: values.References,
      Neighborhood: values.Neighborhood,
      State: values.State,
      Department: values.Department,
    },
    CL: {
      Street: values.Street,
      Address_Number: values.Address_Number,
      References: values.References,
      Neighborhood: values.Neighborhood,
      State: values.State,
    },
    PE: {
      Street: values.Street,
      Address_Number: values.Address_Number,
      References: values.References,
      Neighborhood: values.Neighborhood,
      State: values.State,
      Department: values.Department,
    },
    Default: {
      Street: values.Street,
      Address_Number: values.Address_Number,
      References: values.References,
      Zip_Code: values.Zip_Code,
      Neighborhood: neighborhoodSel.value,
      State: [
        stateSelection?.label,
        stateSelection?.value,
        stateSelection?.id,
        stateSelection?.selected,
      ],
    },
  };
  return newValues[country];
};
export const buttonValidationByBountry = (dataButtonValidation) => {
  const {
    country,
    formik,
    neighborhoodSel,
    neighborhoods,
    stateSelection,
    zipCodeError,
  } = dataButtonValidation;

  const validations = {
    MX: (formik.values.Street !== ''
      && formik.values.Address_Number !== ''
      && formik.values.Zip_Code?.length == 5 && !zipCodeError
      && neighborhoodSel.value !== ''
      && neighborhoods.length > 0
      && stateSelection.value !== 'DEFAULT' && neighborhoodSel.value !== '0'),
    CO: (formik.values.Street !== ''
      && formik.values.Address_Number !== ''
      && formik.values.Neighborhood !== ''
      && formik.values.State !== ''
      && formik.values.Department !== ''),
    CL: (formik.values.Street !== ''
    && formik.values.Address_Number !== ''
    && formik.values.Neighborhood !== ''
    && formik.values.State !== ''),
    PE: (formik.values.Street !== ''
    && formik.values.Address_Number !== ''
    && formik.values.Neighborhood !== ''
    && formik.values.State !== ''
    && formik.values.Department !== ''),
    Default: (formik.values.Street !== ''
      && formik.values.Address_Number !== ''
      && formik.values.Zip_Code?.length == 5 && !zipCodeError
      && neighborhoodSel.value !== ''
      && neighborhoods.length > 0
      && stateSelection.value !== 'DEFAULT' && neighborhoodSel.value !== '0'),
  };
  return validations[country];
};
