import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from '../../containers/Payment/Payment.module.css';
import Box from '../Box';
import Text from '../Text';
import { contentCopy } from '../../assets/images/Global';

const CopyClipboard = ({
  id,
  reference,
  size14,
}) => {
  return (
    <Box
      fd="row"
      jc="space-between"
      bg="#F5F5F5"
      p="8px 16px"
      className={styles.copyContainer}
    >
      <Text s={size14}>
        {reference}
      </Text>
      <ReactTooltip
        place="top"
        id={id}
        effect="solid"
        globalEventOff="click"
        className={styles.customTooltip}
        offset="{'left': 29}"
        backgroundColor="#212121"
        delayHide={800}
        delayShow={0}
        afterShow={() => {
          navigator.clipboard.writeText(reference);
          setTimeout(ReactTooltip.hide(), 3000);
        }}
      >
        copiado
      </ReactTooltip>
      <button
        id="Check_Clipboard_CopyToClipboard"
        type="button"
        data-for={id}
        data-tip="show the tips!"
        data-event="click"
      >
        <img src={contentCopy} alt="Copy" height={20} width={20} />
      </button>
    </Box>
  );
};

CopyClipboard.propTypes = {
  id: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  size14: PropTypes.number.isRequired,
};

export default CopyClipboard;
