/**
 * This function returns the correct price depending if the product has a discount
 * @param {object} product
 * @returns {Number} price in centamount
 */

function getCentAmount(product) {
  if (product.price.discounted) {
    return product.price.discounted.value.centAmount;
  }
  return product.price.value.centAmount;
}

export default getCentAmount;
