import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mymoons/ui-library';
import styles from './RefoundModal.module.css';
import { close, shoppingBag } from '../../assets/images/Global';
import GlobalContext from '../../context/Global/Context';

const RefoundModal = ({ refoundModal, setRefoundModal }) => {
  const [animation, setAnimation] = useState(true);
  const globalContext = useContext(GlobalContext);
  const { country } = globalContext;

  return (
    <div className={styles.container}>
      <div
        className={
          animation
            ? `${styles.modal} ${styles.animationEaseIn}`
            : `${styles.modal} ${styles.animationEaseOut}`
        }
      >
        <div className={styles.header}>
          <p className={styles.title}>Políticas de Moons</p>
          <button
            id="RefoundModal_CloseButton"
            className={styles.closeButton}
            onClick={() => {
              setAnimation(false);
              setTimeout(() => {
                setRefoundModal(!refoundModal);
              }, 500);
            }}
            type="button"
          >
            <img src={close} alt="Close Button" />
          </button>
        </div>
        <div className={styles.box}>
          <h1 className={styles.titleContent}>Reembolsos</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.shoppingContainer}>
            <img width={20} height={20} src={shoppingBag} alt="Shopping Bag" />
            <p>Política de reembolso del 100%</p>
          </div>
          <ul>
            <li>
              El valor de tu cita se abonará a tu tratamiento en caso de que
              quieras continuar.
            </li>
            <li>
              Si asistes a tu cita sin reagendar y no quieres continuar, te
              devolvemos el 100% del valor de la cita.
            </li>
            <li>
              <Button
                className={styles.buttonGray}
                color="dark"
                href={`https://legal.mymoons.${country.toLocaleLowerCase()}/politica-de-devoluciones`}
                label="Ver política completa"
                rel="noopener noreferrer"
                size="small"
                target="_blank"
                variant="plain"
              />
            </li>
          </ul>
        </div>
      </div>
      <button
        id="RefoundModal_HideCloseButton"
        onClick={() => {
          setRefoundModal(!refoundModal);
        }}
        type="button"
        aria-label="area"
        className={styles.hiddenButton}
      />
    </div>
  );
};

RefoundModal.propTypes = {
  refoundModal: PropTypes.bool.isRequired,
  setRefoundModal: PropTypes.shape.isRequired,
};

export default RefoundModal;
