/**
 * This function register the precision event for mixpanel & posthoc
 * @param {object} data
 */

function sendFreshpaintEvent(eventName, data) {
  if (window.freshpaint) {
    const { freshpaint } = window;
    try {
      freshpaint.track(eventName, data);
    } catch (error) {
      throw new Error(`No se pudo enviar el evento ${eventName}`);
    }
  }
}

export default sendFreshpaintEvent;
