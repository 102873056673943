/* eslint-disable max-len */
import convertPrice from './convertPrice';
import getCentAmount from '../utils/getCentAmount';

/**
 * Return product data by country
 *
 * @param {array} lineItems of products
 * @param {boolean} isConvertedPrice
 * @param {boolean} isToShowProducts
 * @returns {array} products
 */
function useProductDataByCountry(
  lineItems,
  isConvertedPrice,
  isToShowProducts,
) {
  const products = [];

  const language = (product) => {
    const { currencyCode } = product.totalPrice;

    const countryName = {
      MXN: 'MX',
      COP: 'CO',
      CLP: 'CL',
      PEN: 'PE',
    };

    return product.name[`es-${countryName[currencyCode]}`];
  };

  if (!isToShowProducts) {
    lineItems.forEach((productsArr) => {
      productsArr.forEach((product) => {
        const images = product.variant ? product.variant.images : '';
        const centAmount = getCentAmount(product);
        if (product.discountedPricePerQuantity.length === 0) {
          const productsConvert = {
            id: product.id,
            productId: product.productId,
            name: language(product),
            price: isConvertedPrice ? convertPrice(centAmount) : centAmount,
            quantity: product.quantity,
            totalPrice: isConvertedPrice
              ? convertPrice(centAmount * product.quantity)
              : centAmount * product.quantity,
            images,
          };
          products.push(productsConvert);
        } else {
          const productsConvert = {
            id: product.id,
            productId: product.productId,
            name: language(product),
            price: isConvertedPrice
              ? convertPrice(product?.discountedPricePerQuantity[0]?.discountedPrice?.value.centAmount)
              : product?.discountedPricePerQuantity[0]?.discountedPrice?.value.centAmount,
            quantity: product.quantity,
            totalPrice: isConvertedPrice
              ? convertPrice(centAmount * product.quantity)
              : centAmount * product.quantity,
            images,
          };
          products.push(productsConvert);
        }
      });
    });
  } else {
    lineItems.forEach((productsArr) => {
      productsArr.forEach((product) => {
        const images = product.variant ? product.variant.images : '';
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: language(product),
          price: isConvertedPrice
            ? convertPrice(Number(centAmount / 100).toFixed(2))
            : centAmount / 100,
          quantity: product.quantity,
          totalPrice: isConvertedPrice
            ? convertPrice(Number((centAmount / 100)).toFixed(2))
            : (centAmount / 100),
          images,
          principalPrice: product.price.discounted
            ? convertPrice(Number((product.price.discounted.value.centAmount / 100) * product.quantity)
              .toFixed(2))
            : convertPrice(Number((product.price.value.centAmount / 100) * product.quantity)
              .toFixed(2)),
          hasDiscount: !!product.price.discounted,
        };
        products.push(productsConvert);
      });
    });
  }

  return products;
}

export default useProductDataByCountry;
