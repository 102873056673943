import axios from 'axios';
import PropTypes from 'prop-types';

const axiosPost = (data) => {
  const { REACT_APP_API_URL } = process.env;

  const response = axios.post(
    `${REACT_APP_API_URL}/checkout`,
    data,
  );

  return response;
};

axiosPost.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string.isRequired }),
};

export default axiosPost;
