import axios from 'axios';

const {
  REACT_APP_CHECKOUT_API_URL,
  REACT_APP_CHECKOUT_AUTH_USERNAME,
  REACT_APP_CHECKOUT_AUTH_PASSWORD,
  REACT_APP_ENVIRONMENT,
  REACT_APP_PAYPAL_MOCK_CODE,
} = process.env;

const axiosPostAuthorize = async (data) => {
  const options = {
    auth: {
      username: REACT_APP_CHECKOUT_AUTH_USERNAME,
      password: REACT_APP_CHECKOUT_AUTH_PASSWORD,
    },
  };

  const response = axios.post(
    `${REACT_APP_CHECKOUT_API_URL}/paypal/authorize`,
    data,
    options,
  );

  return response;
};

const axiosPostCapture = async (orderId, data) => {
  const options = {
    auth: {
      username: REACT_APP_CHECKOUT_AUTH_USERNAME,
      password: REACT_APP_CHECKOUT_AUTH_PASSWORD,
    },
  };
  if (REACT_APP_ENVIRONMENT === 'development' && REACT_APP_PAYPAL_MOCK_CODE) {
    options.headers = {
      'PayPal-Mock-Response': JSON.stringify({
        mock_application_codes: REACT_APP_PAYPAL_MOCK_CODE,
      }),
    };
  }
  const response = axios.post(
    `${REACT_APP_CHECKOUT_API_URL}/paypal/${orderId}/capture`,
    data,
    options,
  );

  return response;
};

export { axiosPostAuthorize, axiosPostCapture };
