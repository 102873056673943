import { paymentInstallments } from '../components/Methods/components/MethodsFlow/components/CreditCard/components/CardForm/data';

/**
 * This function return string number with commas and erase decimals if not exists
 * @param {string} number
 * @param {string} country
 * @returns {string} Price with commas
 */

export const numberWithCommas = (number, country) => {
  let numberConverted = Number(number);
  if (numberConverted % 1 === 0) {
    numberConverted = Math.trunc(numberConverted);
  }

  let formatedNumberWithCommas = numberConverted
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (country === 'MX' || country === 'PE') {
    formatedNumberWithCommas = numberConverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (country === 'CO' || country === 'CL') {
    formatedNumberWithCommas = numberConverted
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return formatedNumberWithCommas;
};

/**
 * This function retun the current price with Installments amount
 * @param {string} price
 * @param {number} months
 * @returns {string} Final price
 */

export const getPriceWithInstallments = (price, months) => {
  const installments = paymentInstallments.filter((payment) => {
    return payment.months === months;
  });
  return ((price * (1 + installments[0].taxe)) / months).toFixed(2);
};

/**
 * This function retun the current price
 * @param {string} price
 * @param {number} months
 * @param {string} country
 * @returns {string} Total  price
 */

export const getTotalPrice = (price, months, country) => {
  const totalPrice = price.split('$')[1];
  const priceDecimal = totalPrice.replaceAll(',', '');
  let finalPrice = priceDecimal;
  if (months !== 0) {
    const priceByMonth = getPriceWithInstallments(priceDecimal, months);
    finalPrice = (priceByMonth * months).toFixed(2);
  }
  return numberWithCommas(finalPrice, country);
};
