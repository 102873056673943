/**
 * Return price with commas
 *
 * @param {string} price
 * @returns {string} final price converted
 */
function convertPrice(price) {
  let priceDecimals;
  let priceConverted = '';

  if (price % 1 === 0) {
    priceDecimals = Math.trunc(price);
  } else {
    priceDecimals = price;
  }

  priceConverted = priceDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$${priceConverted}`;
}

export default convertPrice;
