import axios from 'axios';
import PropTypes from 'prop-types';

const {
  REACT_APP_ECOMMERCE_BASE_URL,
  REACT_APP_OMS_BASE_URL,
  REACT_APP_LOCATION_API,
} = process.env;

const axiosGet = async (url) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };
  const response = axios.get(`${REACT_APP_ECOMMERCE_BASE_URL}/${url}`, options);

  return response;
};

axiosGet.propTypes = {
  url: PropTypes.string.isRequired,
};

const axiosGetOrderId = async (cartId) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };
  const response = axios.get(
    `${REACT_APP_OMS_BASE_URL}/orders?limit=25&page=1&cartId=${cartId}`,
    options,
  );

  return response;
};

const axiosGetAddressInfo = async (zipCode) => {
  const response = axios.get(
    `${REACT_APP_LOCATION_API}/zip-codes/${zipCode}`,
  );
  return response;
};

export { axiosGet, axiosGetOrderId, axiosGetAddressInfo };
