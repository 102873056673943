/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  payPalColor,
  priceCheck,
} from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import {
  axiosPostAuthorize,
  axiosPostCapture,
} from '../../../../../../methods/payPal/axiosPost';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import sendFreshpaintEvent from '../../../../../../utils/sendFreshpaintEvent';
import AlertModal from '../../../../../AlertModal';
import { fetchSocialByCountry } from '../../../../../../utils/locale';
import data from '../../data';

const countries = {
  MXN: 'México',
  COP: 'Colombia',
  CLP: 'Chile',
  PEN: 'Perú',
};

const FlowPayPal = () => {
  const productContext = useContext(ProductContext);
  const thankYouContext = useContext(ThankYouContext);
  const globalContext = useContext(GlobalContext);
  const [preventModal, setPreventModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { setDataPayPal } = thankYouContext;
  const { titlePayPal } = data.paypal;

  const {
    couponsCode,
    productsWithDiscount,
    carts: cartsProducts,
  } = productContext;
  const {
    isCitaFlow,
    isOnlyComplementaryPayment,
    isOnlyAdvance,
    name,
    email,
    customerId,
    country,
    publicKey,
    origin,
    redirectUrl,
  } = globalContext;

  const { code, number, whatsAppUrl } = fetchSocialByCountry(
    country,
    'whatsapp'
  );

  const dataToPayPal = organizeDataPayments();

  const carts = new URLSearchParams(window.location.search).getAll('cart');

  const dataToFreshpaint = {
    customer_id: customerId,
    distinct_id: customerId,
    product_id: cartsProducts[0]?.lineItems[0]?.id,
    cart_id: cartsProducts[0]?.id,
    product_quantity: cartsProducts[0]?.lineItems.length,
    public_key: publicKey,
    country_ops: country,
    currency: 'MXN',
    payment_method: 'oxxo',
    payment_gateway: 'spei',
    product_category: 'appointment',
    product: 'appointment-mx',
    payment_type: 'Complete',
    platform: 'checkout_v3',
    transaction_amount: cartsProducts[0]?.totalPrice.centAmount / 100,
    name,
    email,
  };

  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    button: 'Regresar a la tienda',
    imgAlert: priceCheck,
    urlRedirect: redirectUrl,
    discardLink: redirectUrl,
  };

  const paymentErrorModal = {
    title: '¡Lo sentimos!',
    description:
      'Ocurrió un error al procesar tu pago. Contacta con el equipo de Soporte de Moons.',
    button: 'Contactar a soporte',
    imgAlert: priceCheck,
    urlRedirect: `${whatsAppUrl}${code}${number}&text=¡Hola, tengo un problema con mi pago!`,
    target: '_blank',
    discardLink: redirectUrl,
  };

  useEffect(() => {
    if (productsWithDiscount.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const discountedPrice =
          product.discountedPricePerQuantity[0].discountedPrice.value;
        const { centAmount: centAmountDiscounted } = discountedPrice;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-MX',
          price: centAmountDiscounted,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToPayPal.cartData.totalLineItemQuantity = productsArray.length;
      dataToPayPal.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  React.useEffect(() => {
    const event = new Event('DOMContentLoaded');
    document.dispatchEvent(event);
  }, []);

  const handleCreateOrder = async () => {
    const { data: upToDateCartData } = await axiosPutRecalculateCarts(carts);
    if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
      setPreventModal(true);
    } else {
      setPreventModal(false);
    }

    const lineItems = dataToPayPal.cartData.lineItems.map((item) => {
      return {
        name: item.name,
        unitPrice: item.price,
        quantity: item.quantity,
        totalPrice: item.totalPrice,
      };
    });

    const body = {
      currency: dataToPayPal.cartData.currency,
      totalPrice: dataToPayPal.cartData.totalPrice * 100,
      lineItems,
    };
    const response = await axiosPostAuthorize(body);
    return response?.data?.id;
  };

  const handleApproval = async (param) => {
    const lineItems = dataToPayPal.cartData.lineItems.map((item) => {
      return {
        name: item.name,
        unitPrice: item.price,
        quantity: item.quantity,
        totalPrice: item.totalPrice,
      };
    });

    const body = {
      country: countries[dataToPayPal.cartData.currency],
      payer: dataToPayPal.payer,
      cartData: {
        id: dataToPayPal.cartData.id,
        currency: dataToPayPal.cartData.currency,
        totalPrice: dataToPayPal.cartData.totalPrice * 100,
        lineItems,
        productCategory: dataToPayPal.cartData.productCategory,
        productKey: dataToPayPal.cartData.productKey,
      },
    };
    const response = await axiosPostCapture(param.orderID, body);
    setDataPayPal(response.data);
    let installment;
    if (response.data.credit_financing_offer) {
      installment = {
        term: response.data.credit_financing_offer.term,
        payment:
          response.data.credit_financing_offer.installment_details.payment_due
            .value,
      };
    }
    const tyData = {
      date: response.data?.purchase_units[0]?.payments.captures[0].create_time,
      payment: installment ? installment.term : 0,
      installment,
    };
    localStorage.setItem('tyDataCard', JSON.stringify(tyData));
    dataToFreshpaint.attempted_status = true;
    if (origin === 'appointmentv2') {
      sendFreshpaintEvent('New Checkout - Payment Attempted', dataToFreshpaint);
    }
    window.location.replace(`${window.location.origin}/payment/paypal`);

    return response.data;
  };

  const handleError = (error) => {
    console.log('error:', error);
    dataToFreshpaint.attempted_status = false;
    if (origin === 'appointmentv2') {
      sendFreshpaintEvent('New Checkout - Payment Attempted', dataToFreshpaint);
    }
    setErrorModal(true);
  };

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      {errorModal && (
        <AlertModal
          data={paymentErrorModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.containerPayPal}>
        <img
          className={styles.logoPayPal}
          width={96}
          height={25}
          src={payPalColor}
          alt="PayPal Logo"
        />
        <div className={styles.containerTitlePayPal}>
          <p className={styles.title}>{titlePayPal}</p>
        </div>
        <PayPalScriptProvider
          options={{
            'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
            currency: dataToPayPal.cartData.currency,
          }}
        >
          <PayPalButtons
            style={{ color: 'black', height: 44 }}
            fundingSource="paypal"
            createOrder={handleCreateOrder}
            onApprove={handleApproval}
            onError={handleError}
            className={styles.containerButtonPayPal}
          />
        </PayPalScriptProvider>
      </div>
    </>
  );
};

export default FlowPayPal;
