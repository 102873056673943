import md5 from 'md5';

const createPayuScripts = () => {
//   window.payU.setURL(process.env.REACT_APP_PAYU_URL);
//   window.payU.setPublicKey(process.env.REACT_APP_PAYU_PUBLIC_KEY);
//   window.payU.setAccountID(process.env.REACT_APP_PAYU_ID);
//   window.payU.setListBoxID('cardID');
  const sessionId = md5('moons-checkout');
  document.cookie = `session_Id=${sessionId}`;
  const microtime = Date.now() * 1000;
  const deviceSessionId = md5(`${sessionId}${microtime}`);
  const script = document.createElement('script');
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.style = 'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;';
  iframe.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200`;
  script.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200`;
  script.async = true;
  noScript.appendChild(iframe);
  script.id = 'script-payu';
  noScript.id = 'noscript-payu';
  document.body.appendChild(script);
  document.body.appendChild(noScript);
  localStorage.setItem('cookie', md5(sessionId));
  localStorage.setItem('sessionId', sessionId);
  localStorage.setItem('deviceSessionId', deviceSessionId);
};

export default createPayuScripts;
