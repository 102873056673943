import React, { useContext, useEffect, useState } from 'react';
import styles from './PaymentMethod.module.css';
import Methods from '../../components/Methods';
import Address from '../../components/Address';
import { Footer } from '../../components/Footer';
import GlobalContext from '../../context/Global/Context';

const PaymentMethod = () => {
  const globalContext = useContext(GlobalContext);
  const [showAddress, setShowAddress] = useState(true);
  const {
    isCitaFlow,
    isOnlyComplementaryPayment,
    isOnlyAdvance,
    country,
  } = globalContext;

  useEffect(() => {
    if (country === 'MX') {
      if (isCitaFlow || isOnlyComplementaryPayment || isOnlyAdvance) {
        setShowAddress(false);
      }
    } else {
      setShowAddress(true);
    }
  }, [isCitaFlow, isOnlyComplementaryPayment, isOnlyAdvance]);

  return (
    <div className={styles.container}>
      {showAddress && <Address />}
      <Methods />
      <Footer />
    </div>
  );
};

export default PaymentMethod;
