import axios from 'axios';

const { REACT_APP_ECOMMERCE_BASE_URL } = process.env;

const axiosPutAddCoupon = async (cartId, coupon) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };

  const response = axios.put(
    `${REACT_APP_ECOMMERCE_BASE_URL}/carts/${cartId}`,
    {
      actions: [
        {
          action: 'addDiscountCode',
          code: coupon,
        },
      ],
    },
    options,
  );

  return response;
};

const axiosPutRemoveCoupon = async (cartId, coupon) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };

  const response = axios.put(
    `${REACT_APP_ECOMMERCE_BASE_URL}/carts/${cartId}`,
    {
      actions: [
        {
          action: 'removeDiscountCode',
          discountCode: coupon,
        },
      ],
    },
    options,
  );

  return response;
};

const axiosPutSetAddress = async (cartId, newAddress) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };

  const response = axios.put(
    `${REACT_APP_ECOMMERCE_BASE_URL}/carts/${cartId}`,
    {
      actions: [
        {
          action: 'setShippingAddress',
          address: newAddress,
        },
        {
          action: 'setBillingAddress',
          address: newAddress,
        },
      ],
    },
    options,
  );

  return response;
};

const axiosPutSetShippingMethod = async (cartId, Method) => {
  const options = {
    headers: {
      'x-api-key': process.env.REACT_APP_BASIC_API_KEY_ECOMERCE,
    },
  };

  const response = axios.put(
    `${REACT_APP_ECOMMERCE_BASE_URL}/carts/${cartId}`,
    {
      actions: [
        {
          action: 'setShippingMethod',
          shippingMethod: Method,
        },
      ],
    },
    options,
  );

  return response;
};

export {
  axiosPutAddCoupon,
  axiosPutRemoveCoupon,
  axiosPutSetAddress,
  axiosPutSetShippingMethod,
};
