import Constants from '../../../utils/Constants';

const { assetsURL } = Constants;

const logoMoons = `${assetsURL}/images/logo-moons.svg`;
const certified1x = `${assetsURL}/images/certified@1x.webp`;
const certified2x = `${assetsURL}/images/certified@2x.webp`;
const certified3x = `${assetsURL}/images/certified@3x.webp`;
const arrow = `${assetsURL}/images/left-arrow.svg`;

export {
  logoMoons,
  certified1x,
  certified2x,
  certified3x,
  arrow,
};
