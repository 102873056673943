import { DateTime } from 'luxon';

function capitalize(str) {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

const countryFormat = {
  México: 'es-MX',
  Colombia: 'es-CO',
  default: 'es-MX',
};

const dateFormatter = (date, patient) => {
  const dateToFormmat = DateTime.fromISO(date).setLocale(
    countryFormat[patient.Country_Ops]
      ? countryFormat[patient.Country_Ops]
      : countryFormat.default,
  );
  const dayWeek = capitalize(dateToFormmat.toFormat('cccc'));
  const month = capitalize(dateToFormmat.toFormat('LLLL'));
  const day = dateToFormmat.toFormat('d');
  const hours = dateToFormmat.toFormat('h');
  const hoursPM = dateToFormmat.toFormat('H');
  const minutes = dateToFormmat.toFormat('mm');
  const timeSuffix = hoursPM >= 12 ? 'pm' : 'am';
  return `${dayWeek}, ${day} de ${month} - ${hours}:${minutes}${timeSuffix}`;
};

export default dateFormatter;
