import React from 'react';
import styles from './SocialProof.module.css';
import {
  googleLogo,
  facebookLogo,
  starProof,
  halfStarProof,
} from '../../assets/images/Global';

const SocialProof = () => {
  return (
    <div className={styles.container}>
      <div className={styles.proof}>
        <img width={24} height={24} src={googleLogo} alt="Google Logo" />
        <div className={styles.stick} />
        <img width={24} height={24} src={facebookLogo} alt="Facebook Logo" />
        <img width={24} height={24} src={starProof} alt="Star" />
        <img width={24} height={24} src={starProof} alt="Star" />
        <img width={24} height={24} src={starProof} alt="Star" />
        <img width={24} height={24} src={starProof} alt="Star" />
        <img width={24} height={24} src={halfStarProof} alt="Half Star" />
        <p className={styles.rate}>4.8</p>
      </div>
      <p className={styles.disclaimer}>+10,000 sonrisas alineadas con Moons</p>
    </div>
  );
};

export default SocialProof;
