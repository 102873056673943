const {
  REACT_APP_MERCADO_PAGO_KEY_MX,
  REACT_APP_MERCADO_PAGO_KEY_CO,
  REACT_APP_MERCADO_PAGO_KEY_PE,
  REACT_APP_WHATSAPP_MX,
  REACT_APP_WHATSAPP_CO,
  REACT_APP_WHATSAPP_CL,
  REACT_APP_WHATSAPP_PE,
} = process.env;

const availableCountries = {
  mx: {
    key: REACT_APP_MERCADO_PAGO_KEY_MX,
    locale: 'es-MX',
    currency: 'MXN',
    countryCode: 'MX',
    countryName: 'México',
  },
  co: {
    key: REACT_APP_MERCADO_PAGO_KEY_CO,
    locale: 'es-CO',
    currency: 'COP',
    countryCode: 'CO',
    countryName: 'Colombia',
  },
  pe: {
    key: REACT_APP_MERCADO_PAGO_KEY_PE,
    locale: 'es-PE',
    currency: 'PEN',
    countryCode: 'PE',
    countryName: 'Peru',
  },
  us: {
    locale: 'en',
    currency: 'USD',
    countryCode: 'US',
  },
  uk: {
    locale: 'en',
    currency: 'GBP',
    countryCode: 'UK',
  },
};

const availableSocial = {
  mx: {
    whatsApp: {
      code: '+52',
      number: REACT_APP_WHATSAPP_MX,
      whatsAppUrl: 'https://api.whatsapp.com/send?phone=',
      whatsAppMessage: '&text=¡Hola, necesito más información!',
    },
  },
  co: {
    whatsApp: {
      code: '+57',
      number: REACT_APP_WHATSAPP_CO,
      whatsAppUrl: 'https://api.whatsapp.com/send?phone=',
      whatsAppMessage: '&text=¡Hola, necesito más información!',
    },
  },
  cl: {
    whatsApp: {
      code: '+56',
      number: REACT_APP_WHATSAPP_CL,
      whatsAppUrl: 'https://api.whatsapp.com/send?phone=',
      whatsAppMessage: '&text=¡Hola, necesito más información!',
    },
  },
  pe: {
    whatsApp: {
      code: '+51',
      number: REACT_APP_WHATSAPP_PE,
      whatsAppUrl: 'https://api.whatsapp.com/send?phone=',
      whatsAppMessage: '&text=¡Hola, necesito más información!',
    },
  },
};

/**
 * Returns mercado pago token by country
 * @param {string} country
 * @returns {string} Mercado Pago access token
 */

const fetchMPAccessTokenByCountry = (country) => {
  return availableCountries[country.toLowerCase()] || availableCountries.mx;
};

/**
 * Returns whatsapp data by country
 * @param {string} country
 * @returns {string} Whatsapp object with all data for the link
 */

const fetchSocialByCountry = (country) => {
  return (
    availableSocial[country.toLowerCase()].whatsApp
    || availableCountries.mx.whatsApp
  );
};

export {
  fetchMPAccessTokenByCountry,
  fetchSocialByCountry,
  availableCountries,
};
