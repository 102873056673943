import axios from 'axios';
import { Buffer } from 'buffer';

/**
 *
 * @returns {object} Deal Appointment Data
 */

const basicAuthHeader = () => {
  const { REACT_APP_BASIC_AUTH_USERNAME, REACT_APP_BASIC_AUTH_PASSWORD } = process.env;
  const user = REACT_APP_BASIC_AUTH_USERNAME;
  const password = REACT_APP_BASIC_AUTH_PASSWORD;
  const auth = `${user}:${password}`;
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`;
  return {
    Authorization: authEncoded,
    'Content-Type': 'application/json',
  };
};

const getDataBack4app = (table, publicKey, order) => {
  const { REACT_APP_MIDDLEWARE_DATABASE } = process.env;
  const options = {
    headers: basicAuthHeader(),
    method: 'POST',
  };

  const data = {
    table,
    columns: {
      PublicKey: publicKey,
    },
    order,
  };

  return axios.post(`${REACT_APP_MIDDLEWARE_DATABASE}`, data, options);
};

export default getDataBack4app;
