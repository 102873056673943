import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const { REACT_APP_SENTRY_DNS_URL, REACT_APP_ENVIRONMENT } = process.env;

/**
 * Track errors with Sentry
 */

function sentryInit() {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DNS_URL,
    environment: REACT_APP_ENVIRONMENT,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%

    // of transactions for performance monitoring.

    // We recommend adjusting this value in production

    tracesSampleRate: 1.0,
  });
}

export default sentryInit;
