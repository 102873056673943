/* eslint-disable */

import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './AddressSelector.module.css';
import stylesTooltip from '../../../../containers/Payment/Payment.module.css';
import PaymentContext from '../../../../context/Payment/Context';
import data from './data';
import GlobalContext from '../../../../context/Global/Context';

const AddressSelector = () => {
  const paymentContext = useContext(PaymentContext);
  const { address, showCloseAddressModal, hasAddress } = paymentContext;
  const disabledAddress = address.ChangePaymentType;
  const globalContext = useContext(GlobalContext);
  const { country, emptyAddress } = globalContext;
  const { defaultText } = data[country] || data.defaultText;
  const AddressTextsByCountry = (addressData) => {
    let countryText = country;
    if (countryText === '') {
      countryText = 'Default';
    }
    const { Street, Address_Number, Neighborhood, Zip_Code, State } =
      addressData;
    let stateText = '';
    if (State && State[0]) {
      stateText = State[0];
    } else {
      stateText = State;
    }
    const Address = {
      MX: {
        titleAddressTest1: Street,
        titleAddresstext2: Address_Number,
        textAddress1: Neighborhood,
        textAddress2: `${Zip_Code}, `,
        textAddress3: stateText,
      },
      CO: {
        titleAddressTest1: Street,
        titleAddresstext2: '',
        textAddress1: Address_Number,
        textAddress2: `${Neighborhood},`,
        textAddress3: State,
        textAddress4: 'Colombia',
      },
      CL: {
        titleAddressTest1: Street,
        titleAddresstext2: '',
        textAddress1: Address_Number,
        textAddress2: `${Neighborhood},`,
        textAddress3: State,
        textAddress4: 'Chile',
      },
      PE: {
        titleAddressTest1: Street,
        titleAddresstext2: '',
        textAddress1: Address_Number,
        textAddress2: `${Neighborhood},`,
        textAddress3: State,
        textAddress4: 'Perú',
      },
      Default: {
        titleAddressTest1: Street,
        titleAddresstext2: Address_Number,
        textAddress1: Neighborhood,
        textAddress2: `${Zip_Code}, `,
        textAddress3: stateText,
      },
    };
    return Address[countryText];
  };
  const AddressResult = AddressTextsByCountry(address);

  return (
    <>
      <button
        id="Check_AddressSelector_SelectAddress"
        type="button"
        onClick={() => {
          if (!disabledAddress) {
            showCloseAddressModal(true);
          }
        }}
        className={emptyAddress ? styles.containerError : styles.container}
        data-tip
        data-for="tooltip-error-address"
        style={disabledAddress ? { cursor: 'no-drop' } : {}}
      >
        <ReactTooltip
          place="top"
          id="tooltip-error-address"
          disable={!disabledAddress}
          effect="solid"
          offset={{ left: -125 }}
          className={stylesTooltip.customTooltipAddress}
          backgroundColor="#212121"
        >
          No es posible modificar la dirección una vez realizada una orden
        </ReactTooltip>
        {hasAddress ? (
          <>
            <div className={styles.containerAddress}>
              <p className={styles.titleAddress}>
                {AddressResult.titleAddressTest1}{' '}
                {AddressResult.titleAddresstext2}
              </p>
              <p className={styles.text}>
                {AddressResult.textAddress1}, {AddressResult.textAddress2}{' '}
                {AddressResult.textAddress3}, {AddressResult.textAddress4}
              </p>
            </div>
            <p className={styles.Add_address}>Editar</p>
          </>
        ) : (
          <>
            <p className={styles.text}>{defaultText}</p>
            <p className={styles.Add_address}>Agregar</p>
          </>
        )}
      </button>
      {emptyAddress && (
        <div style={{ marginTop: 16 }} className="ErrorContainer">
          <span className="material-icons ErrorIcon">error</span>
          <span className="ErrorMessage">
            Ingresa tu dirección para continuar.
          </span>
        </div>
      )}
    </>
  );
};

export default AddressSelector;
