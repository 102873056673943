import React, { useEffect, useContext } from 'react';
import GlobalContext from '../../context/Global/Context';
import PaymentMethod from '../PaymentMethod';
import Summary from '../Summary';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  const globalContext = useContext(GlobalContext);
  const { customerId, name, email } = globalContext;

  useEffect(() => {
    if (window.freshpaint) {
      const { freshpaint } = window;
      window.localStorage.setItem('CustomerId', customerId);
      window.dataLayer.push({ event: 'loadPixel' });
      freshpaint.identify(customerId, {
        customer_id: customerId,
        name,
        email,
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <PaymentMethod />
      <Summary />
    </div>
  );
};

export default Dashboard;
