import React from 'react';
import PropTypes from 'prop-types';

const Box = ({
  w, // width
  d, // display
  jc, // justifyContent
  ai, // alignItems
  p, // padding
  fd, // flexDirection
  bg, // backgroundColor
  children,
  className,
}) => {
  const styles = {
    width: w,
    display: d,
    justifyContent: jc,
    alignItems: ai,
    flexDirection: fd,
    padding: p,
    backgroundColor: bg,
  };

  return (
    <div className={className} style={styles}>
      {children}
    </div>
  );
};

Box.propTypes = {
  w: PropTypes.string,
  d: PropTypes.string,
  jc: PropTypes.string,
  ai: PropTypes.string,
  p: PropTypes.string,
  fd: PropTypes.string,
  bg: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};

Box.defaultProps = {
  w: '100%',
  d: 'flex',
  jc: 'center',
  ai: 'center',
  p: '0',
  fd: 'column',
  bg: '',
  className: null,
};

export default Box;
