const formPSE = {
  clientType: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: null,
    },
    {
      id: 1,
      label: 'Persona Natural',
      selected: false,
      value: 'N',
    },
    {
      id: 2,
      label: 'Persona Jurídica',
      selected: false,
      value: 'J',
    },
  ],
  document: [
    {
      id: 0,
      label: 'Selecciona una opción',
      selected: false,
      value: 0,
      regex: null,
    },
    {
      id: 1,
      label: 'Cédula de ciudadanía',
      selected: false,
      value: 'CC',
      regex: 'num',
    },
    {
      id: 2,
      label: 'Cédula de extranjería',
      selected: false,
      value: 'CE',
      regex: 'alfa',
    },
    {
      id: 3,
      label: 'Número de Identificación Tributario',
      selected: false,
      value: 'NIT',
      regex: 'num',
    },
    {
      id: 4,
      label: 'Tarjeta de Identidad',
      selected: false,
      value: 'TI',
      regex: 'num',
    },
    {
      id: 5,
      label: 'Pasaporte',
      selected: false,
      value: 'PP',
      regex: 'alfa',
    },
    {
      id: 6,
      label: 'Identificador único de cliente',
      selected: false,
      value: 'IDC',
      regex: 'alfa',
    },
    {
      id: 7,
      label: 'Número móvil',
      selected: false,
      value: 'CEL',
      regex: 'num',
    },
    {
      id: 8,
      label: 'Registro civil',
      selected: false,
      value: 'RC',
      regex: 'num',
    },
    {
      id: 9,
      label: 'Documento de identificación extranjero',
      selected: false,
      value: 'DE',
      regex: 'alfa',
    },
  ],
};

export default formPSE;
