import Constants from '../../../utils/Constants';

const { assetsURL } = Constants;

const chevron = `${assetsURL}/images/chevron.webp`;
const close = `${assetsURL}/images/close.svg`;
const checkMobile = `${assetsURL}/images/check_mobile.webp`;
const checkDesktop = `${assetsURL}/images/check_desktop.webp`;
const blueInterrogationMobile = `${assetsURL}/images/interrogation_mobile.svg`;
const blueInterrogationDesktop = `${assetsURL}/images/interrogation_desktop.svg`;
const yellowInterrogationMobile = `${assetsURL}/images/yellow_interrogation_mobile.webp`;
const yellowInterrogationDesktop = `${assetsURL}/images/yellow_interrogation_desktop.webp`;
const cardVisa = `${assetsURL}/images/card_visa.svg`;
const productMoons1x = `${assetsURL}/images/product_moons@1x.webp`;
const productMoons2x = `${assetsURL}/images/product_moons@2x.webp`;
const productMoons3x = `${assetsURL}/images/product_moons@3x.webp`;
const productToothbrush1x = `${assetsURL}/images/product_toothbrush@1x.webp`;
const productToothbrush2x = `${assetsURL}/images/product_toothbrush@2x.webp`;
const productToothbrush3x = `${assetsURL}/images/product_toothbrush@3x.webp`;
const productWhitening1x = `${assetsURL}/images/product_whitening@1x.webp`;
const productWhitening2x = `${assetsURL}/images/product_whitening@2x.webp`;
const productWhitening3x = `${assetsURL}/images/product_whitening@3x.webp`;
const whatsappBlack = `${assetsURL}/images/whatsapp_black.webp`;
const contentCopy = `${assetsURL}/images/content_copy.svg`;
const barcode = `${assetsURL}/images/barcode.webp`;
const zoomBarcode = `${assetsURL}/images/zoom_barcode.webp`;
const fileDownload = `${assetsURL}/images/file_download.webp`;
const closeBlack = `${assetsURL}/images/close_black.webp`;
const editIcon = `${assetsURL}/images/edit_icon.webp`;
const oxxo = `${assetsURL}/images/oxxo.svg`;
const efecty = `${assetsURL}/images/efecty.png`;
const oxxoDisabled = `${assetsURL}/images/oxxo_disabled.svg`;
const monetization = `${assetsURL}/images/monetization.svg`;
const placePrimary = `${assetsURL}/images/place_primary.webp`;
const circlePlus = `${assetsURL}/images/circle_plus.webp`;
const spei = `${assetsURL}/images/spei.svg`;
const mastercard = `${assetsURL}/images/mastercard.svg`;
const amex = `${assetsURL}/images/amex.svg`;
const payPal = `${assetsURL}/images/paypal.svg`;
const payPalColor = `${assetsURL}/images/paypal-color.svg`;
const atratoColor = `${assetsURL}/images/atrato_color.webp`;
const products1x = `${assetsURL}/images/products@1x.webp`;
const products2x = `${assetsURL}/images/products@2x.webp`;
const products3x = `${assetsURL}/images/products@3x.webp`;
const chevronMobile = `${assetsURL}/images/chevron_mobile.webp`;
const lock = `${assetsURL}/images/lock.svg`;
const certified1x = `${assetsURL}/images/certified@1x.webp`;
const cardCvc = `${assetsURL}/images/card_cvc.svg`;
const backCheckout = `${assetsURL}/images/back_checkout.webp`;
const creditBlank = `${assetsURL}/images/credit_blank.svg`;
const infoBlack = `${assetsURL}/images/info_black.svg`;
const priceCheck = `${assetsURL}/images/price_check.webp`;
const star = `${assetsURL}/images/star.svg`;
const halfStar = `${assetsURL}/images/half_star.svg`;
const emptyProduct = `${assetsURL}/images/empty-product.webp`;
const timer = `${assetsURL}/images/timer.webp`;
const googleLogo = `${assetsURL}/images/google_logo.svg`;
const facebookLogo = `${assetsURL}/images/facebook_logo.svg`;
const starProof = `${assetsURL}/images/star_proof.svg`;
const halfStarProof = `${assetsURL}/images/half_star_proof.svg`;
const shoppingBag = `${assetsURL}/images/shopping_bag.svg`;
const pseLogo = `${assetsURL}/images/pse.svg`;
const mercadoPago = `${assetsURL}/images/mercado-pago.png`;
const mercadoPagoMobile = `${assetsURL}/images/mercado-pago-mobile.png`;

export {
  chevron,
  close,
  cardVisa,
  checkMobile,
  checkDesktop,
  blueInterrogationMobile,
  blueInterrogationDesktop,
  yellowInterrogationMobile,
  yellowInterrogationDesktop,
  productMoons1x,
  productMoons2x,
  productMoons3x,
  productToothbrush1x,
  productToothbrush2x,
  productToothbrush3x,
  productWhitening1x,
  productWhitening2x,
  productWhitening3x,
  whatsappBlack,
  contentCopy,
  barcode,
  zoomBarcode,
  fileDownload,
  closeBlack,
  editIcon,
  oxxo,
  efecty,
  oxxoDisabled,
  monetization,
  placePrimary,
  circlePlus,
  spei,
  mastercard,
  amex,
  atratoColor,
  products1x,
  products2x,
  products3x,
  chevronMobile,
  lock,
  certified1x,
  cardCvc,
  backCheckout,
  creditBlank,
  infoBlack,
  priceCheck,
  star,
  halfStar,
  emptyProduct,
  timer,
  googleLogo,
  facebookLogo,
  starProof,
  halfStarProof,
  shoppingBag,
  pseLogo,
  payPal,
  payPalColor,
  mercadoPago,
  mercadoPagoMobile,
};
